<template>
	<div 
		v-if="
			leftEye || 
			rightEye ||
			olhoDireitoCilindrico ||
			olhoDireitoEixo ||
			olhoDireitoEsferico ||
			olhoEsquerdo ||
			olhoEsquerdoCilindrico ||
			olhoEsquerdoEixo ||
			olhoEsquerdoEsferico ||
        	possuiAutorrefrator	
		"
		class="eye-container" 
		v-bind:class="{ 'margins': eyeLabel }"	
	>
		<div class="container-header">
			<h4 v-if="eyeLabel">{{ title }}</h4>
			<p class="subtitle" v-else>{{ title }}</p>
			<div class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
				<ChevronUp />
			</div>
		</div>
		<b-row>
			<b-col cols="6">
				<div class="values  " v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
					<div >
						<div class="input-eye">
							<p class="tag-eye"><EyeFill /> D</p>
							<TextArea  id="direito" rows="1" :value="rightEye" :readonly="true" aria-label="Olho direito" />
						</div>
					</div>
				</div>
			</b-col>
			<b-col cols="6">
				<div class="values  " v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
					<div>
						<div class="input-eye">
							<p class="tag-eye"><EyeFill /> E</p>
							<TextArea id="esquerdo" rows="1" :value="leftEye" :readonly="true" aria-label="Olho esquerdo" />
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	<div class="info" v-if="items && title === 'Lente de contato'"
			v-bind:class="{ openTable: open, closeTable: !open }">
			<b-col>
        <div class="custom-input-group" >
          <div class="custom-input">
              <div class="eye-area" >
                <EyeFill /> D
              </div>
              <div class="custom-input eye-box-container">
                <div class="text-area type-2 with-br">
                  <span >AV</span>
                  <div >
                    <input
                      autocomplete="off"
                      type="text"
                      class="form-control text-center"
                      :value="items?.olhoDireitoAV"
                      :disabled="true"
                    >
                  </div>
                </div>
              </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <span>Eixo</span>
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoDireitoEixo"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <span>Curva</span>
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoDireitoCurva"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <span>Modelo</span>
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoDireitoModelo"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <span>Diametro</span>
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoDireitoDiametro"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <span>Esferico</span>
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoDireitoEsferico"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2">
                <span>Cilindrico</span>
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoDireitoCilindrico"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            </div>
            <div class="custom-input no-bb">
            <div class="eye-area">
              <EyeFill /> E
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoEsquerdoAV"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoEsquerdoEixo"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoEsquerdoCurva"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoEsquerdoModelo"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoEsquerdoDiametro"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
              <div class="text-area type-2 with-br">
                <div >
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control text-center"
                    :value="items?.olhoEsquerdoEsferico"
                    :disabled="true"
                  >
                </div>
              </div>
            </div>
            <div class="custom-input eye-box-container">
                <div class="text-area type-2">
                  <div >
                    <input
                      autocomplete="off"
                      type="text"
                      class="form-control text-center"
                      :value="items?.olhoEsquerdoCilindrico"
                      :disabled="true"
                    >
                  </div>
                </div>
              </div>
            </div>
        </div>
      </b-col>
		</div>
		<div class="info" v-if="this.open && title === 'AVCC - Acuidade visual com correção'"
			v-bind:class="{ openTable: open, closeTable: !open }">
			<div class="refrator" v-if="possuiAutorrefrator">
				<span>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="24" height="24" rx="4" fill="#D9DFF2" />
						<path d="M6 12L10 16L19 7" stroke="#A4B1DF" />
					</svg>
				</span>
				Possui auto refrator
			</div>
			<div class="values-refrator" v-if="possuiAutorrefrator">
				<div v-if="olhoDireitoEsferico">
					<h6>Esférico</h6>
					<p>{{ olhoDireitoEsferico }}</p>
				</div>

				<div v-if="olhoDireitoCilindrico">
					<h6>Cilíndrico</h6>
					<p>{{ olhoDireitoCilindrico }}</p>
				</div>

				<div v-if="olhoDireitoEixo">
					<h6>Eixo</h6>
					<p>{{ olhoDireitoEixo }}</p>
				</div>

				<div v-if="olhoEsquerdoEsferico">
					<h6>Esférico</h6>
					<p>{{ olhoEsquerdoEsferico }}</p>
				</div>

				<div v-if="olhoEsquerdoCilindrico">
					<h6>Cilíndrico</h6>
					<p>{{ olhoEsquerdoCilindrico }}</p>
				</div>

				<div v-if="olhoEsquerdoEixo">
					<h6>Eixo</h6>
					<p>{{ olhoEsquerdoEixo }}</p>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
export default {
	components: {
		EyeFill: () => import('@/assets/icons/eye-fill.svg'),
		ChevronUp: () => import('@/assets/icons/chevron-up.svg'),
		TextArea: () => import('@/components/General/TextArea'),
	},

    props: {
        title: String,
        leftEye: String,
        rightEye: String,
        eyeLabel: Boolean,
        olhoDireitoCilindrico: String,
        olhoDireitoEixo: String,
        olhoDireitoEsferico: String,
        olhoEsquerdo: String,
        olhoEsquerdoCilindrico: String,
        olhoEsquerdoEixo: String,
        olhoEsquerdoEsferico: String,
        possuiAutorrefrator: Boolean,
        inline: Boolean,
		    items: Object,
    },

	data() {
		return {
			open: true,
		}
	},

	methods: {
		handleOpen() {
			this.open = !this.open
		}
	},
}
</script>

<style lang="scss" scoped>
.margins {
	margin: 10px 0;
}

.eye-container {
	display: flex;
	flex-direction: column;

	.container-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-right: 30px;

		h3 {
			font-size: 18px;
			color: var(--dark-blue);
			font-weight: 600;
		}

		.chevron {
			width: 25px;
			height: 25px;

			>svg {
				cursor: pointer;
				stroke: var(--neutral-600);
				transition: .5s;
			}
		}

		.rotateUp {
			animation: rotateUp .5s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}

		.rotateDown {
			animation: rotateDown .5s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}

		@keyframes rotateUp {
			0% {
				transform: rotate(0deg);
			}

			100% {
				transform: rotate(180deg);
			}
		}

		@keyframes rotateDown {
			0% {
				transform: rotate(180deg);
			}

			100% {
				transform: rotate(0deg);
			}
		}

	}

	.subtitle {
		color: var(--dark-blue);
		font-weight: 600;
		margin: 5px 0 -5px 0;
	}


	h4 {
		color: var(--dark-blue);
		font-weight: 600;
		font-size: 18px;
	}

	.values {
		margin: 8px 20px 8px 0px;

		>div {

			.eye {
				color: var(--dark-blue);
				font-weight: 600;
				margin-bottom: 5px;
			}
		}
	}

	.form-icon {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	.info {
		display: flex;
		flex-direction: column;

		.refrator {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				margin-right: 5px;
			}
		}

		.values-refrator {
			display: flex;
			flex-direction: row;
			margin: 30px 0;

			>div {
				display: flex;
				flex-direction: column;
				margin-right: 20px;

				h6 {
					font-size: 16px;
					font-weight: 600;
					color: var(--dark-blue);
				}

				p {
					font-size: 16px;
					font-weight: 400;
					color: var(--type-active);
				}
			}

			div:nth-child(3) {
				margin-right: 120px;
			}
		}
	}

    }
	.borderD {
		border-radius: 8px 0 0 0 !important;
		border-bottom: 0px solid var(--neutral-300) !important;
	}
	.borderE {
		border-radius: 0 0 0 8px !important;
	}
	.borderDInput {
		border-radius: 0 8px 0 0 !important;
		border-bottom: 0px solid var(--neutral-300) !important;
	}
	.borderEInput {
		border-radius: 0 0 8px 0 !important;
	}

	.custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    .custom-input {
      flex: 1;
      height: 38px;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;

      .eye-area {
        width: 60px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }

      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);

        .form-control {
          border: 0 !important;
          border-radius: 0 !important;
        }

        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;

          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }
        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
        }
      }
    }
  }
</style>